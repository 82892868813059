import axios from "axios";
import log from "cslog";
import { notiSuccess, notiError } from "../utils/noti";

import { API, errHandler } from "./apis";
import setAuthToken from "../utils/setAuthToken";

// Login User
export const login = (data, onSuccess, onError, onDone) => {
    axios
        .post("/members/login", data, null)
        .then((res) => {
            notiSuccess("Logged In Successfully");
            onSuccess(res.data);
            localStorage.setItem("jwtToken", res.data.data.token);
            localStorage.setItem("user", JSON.stringify(res.data.data.user));
            setAuthToken(res.data.data.token);
        })
        .catch((err) => {
            if (err.response) {
                const data = err.response.data;
                if (data.constructor === "test".constructor) {
                    notiError("Something went wrong");
                } else {
                    notiError(data.message);
                }
                onError();
            } else if (err.request) {
                console.log("Request is made but response not received.");
                notiError("Request is Made but No response received");
            } else {
                console.log("Something went wrong");
                notiError("Something went wrong");
            }
        })
        .finally(() => onDone());
};

export const logout = (onDone) => {
    axios
        .post("/members/logout", null, null)
        .then((res) => {
            notiSuccess("Logged out Successfully");
            localStorage.removeItem("jwtToken");
        })
        .catch((err) => {
            if (err.response) {
                const data = err.response.data;
                if (data.constructor === "test".constructor) {
                    notiError("Something went wrong");
                } else {
                    notiError(data.message);
                }
            } else if (err.request) {
                console.log("Request is made but response not received.");
                notiError("Request is Made but No response received");
            } else {
                console.log("Something went wrong");
                notiError("Something went wrong");
            }
        })
        .finally(() => onDone());
};

export const register = (data, onSuccess, onError, onDone) => {
    axios
        .post("/members/register", data, null)
        .then((res) => {
            notiSuccess("Registered Successfully");
            onSuccess(res.data);
            localStorage.setItem("jwtToken", res.data.data.token);
            localStorage.setItem("user", JSON.stringify(res.data.data.user));
            setAuthToken(res.data.data.token);
        })
        .catch((err) => {
            if (err.response) {
                const data = err.response.data;
                if (data.constructor === "test".constructor) {
                    notiError("Something went wrong");
                } else {
                    notiError(data.message);
                }
                onError();
            } else if (err.request) {
                console.log("Request is made but response not received.");
                notiError("Request is Made but No response received");
            } else {
                console.log("Something went wrong");
                notiError("Something went wrong");
            }
        })
        .finally(() => onDone());
};

export const changePassword = (data, onSuccess, onError, onDone) => {
    axios
        .post("/members/change_password", data, null)
        .then((res) => {
            log.d(res, "Success");
            notiSuccess(res.data.message);
            localStorage.removeItem("jwtToken");
            onSuccess();
        })
        .catch((err) => {
            log.d(err.response, "Error  res");
            if (err.response) {
                const data = err.response.data;
                if (data.constructor === "test".constructor) {
                    notiError("Something went wrong");
                } else {
                    notiError(data.message);
                }
            } else if (err.request) {
                console.log("Request is made but response not received.");
                notiError("Request is Made but No response received");
            } else {
                console.log("Something went wrong");
                notiError("Something went wrong");
            }
            onError();
        })
        .finally(() => onDone());
};
