import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import log from "cslog";
import { changePassword } from "../actions/authActions";

const ChangePasswordDialog = ({ trigger, logout }) => {
    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState(false);

    const formik = useFormik({
        initialValues: {
            old_password: "",
            new_password: "",
            new_password2: "",
        },
        onSubmit: (values) => {
            setBusy(true);
            log.d(values, "Values");
            changePassword(
                values,
                () => {
                    logout();
                    setOpen(false);
                },
                () => {},
                () => {
                    setBusy(false);
                }
            );
        },
    });

    const { old_password, new_password, new_password2 } = formik.values;

    return (
        <Modal
            trigger={trigger}
            size="mini"
            open={open}
            onOpen={() => setOpen(true)}
            onclose={() => setOpen(false)}
        >
            <Modal.Header>Change Password</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input
                        type="password"
                        label="Old Password"
                        name="old_password"
                        value={old_password}
                        onChange={formik.handleChange}
                    />
                    <Form.Input
                        type="password"
                        label="New Password"
                        name="new_password"
                        value={new_password}
                        onChange={formik.handleChange}
                    />
                    <Form.Input
                        type="password"
                        label="Confirm Password"
                        name="new_password2"
                        value={new_password2}
                        onChange={formik.handleChange}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Cancle
                </Button>
                <Button loading={busy} positive onClick={formik.submitForm}>
                    Change Password
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ChangePasswordDialog;
