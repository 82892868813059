import React from "react";
import axios from "axios";
import log from "cslog";
import useSWR from "swr";

const fetcher = (url) => axios.get(url).then((res) => res.data);

export function useAnalytics(id, days) {
    const { data, error } = useSWR(
        `/an/get_an_all/${id ? id : 1}?days=${days}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    // const { data, error } = useSWR(
    //     `http://localhost:5000/an/get_an/${id}?days=${days}`,
    //     fetcher,
    //     { revalidateOnFocus: false }
    // );

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
    };
}
