import { atom } from "recoil";

export const bookListState = atom({
    key: "bookListState",
    default: null,
});

export const anSelectedBook_id = atom({
    key: "anSelectedBook_id",
    default: null,
});
