import React from "react";
import "semantic-ui-css/semantic.min.css";
import AuthView from "./auth/AuthView";
import PrivateRoute from "./auth/PrivateRoute";
import { RecoilRoot } from "recoil";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Home";
import { Toaster } from "react-hot-toast";
import history from "./db/history";

const App = () => {
    return (
        <RecoilRoot>
            {/* <Router history={history}> */}
            <Router>
                <Switch>
                    <Route path="/account" component={AuthView} />
                    <PrivateRoute path="/" component={Home} />
                </Switch>
            </Router>
            <Toaster position="bottom-right" />
        </RecoilRoot>
    );
};

export default App;
