import React from "react";
import {
    PieChart,
    Pie,
    Sector,
    Cell,
    ResponsiveContainer,
    Tooltip,
    Line,
    Legend,
} from "recharts";
import { Loader } from "semantic-ui-react";
import styles from "./styles/AnalyticsView.module.css";

const DeviceChart = ({ data, loading }) => {
    const ddata = [
        { name: "Group A", value: 400 },
        { name: "Group B", value: 300 },
        { name: "Group C", value: 300 },
    ];
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
    return (
        <div
            className={styles.card}
            style={{
                minHeight: "250px",
                width: "50%",
            }}
        >
            <h3
                style={{
                    textAlign: "center",
                    color: "grey",
                    paddingTop: "10px",
                }}
            >
                User Devices
            </h3>

            {/* <ResponsiveContainer width="100%" height="100%"> */}
            {loading ? (
                <Loader active inline="centered" />
            ) : data && data?.length !== 0 ? (
                <PieChart width={300} height={250}>
                    <Pie
                        data={data}
                        cx="40%"
                        cy="50%"
                        innerRadius="50%"
                        outerRadius="70%"
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                        // label
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                    </Pie>
                    <Legend
                        verticalAlign="middle"
                        align="right"
                        layout="vertical"
                        wrapperStyle={
                            {
                                // paddingRight: "10px",
                                // backgroundColor: "yellow",
                            }
                        }
                    />
                    <Tooltip />
                </PieChart>
            ) : (
                <p>No Data Available</p>
            )}
            {/* </ResponsiveContainer> */}
        </div>
    );
};

export default DeviceChart;
