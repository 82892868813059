import React from "react";
// import toast from "toasted-notes";
// import "toasted-notes/src/styles.css";
import { Icon } from "semantic-ui-react";
import log from "cslog";
import toast from "react-hot-toast";

// function noti(msg, icon, primaryColor) {
//     toast.notify(
//         ({ onClose }) => (
//             <div
//                 style={{
//                     borderRadius: "5px",
//                     color: "black",
//                     fontWeight: "bold",
//                     backgroundColor: "white",
//                     boxShadow: "0px 0px 4px 0px rgba(107, 107, 107, 0.71)",
//                     display: "flex",
//                     alignItems: "center",
//                 }}
//             >
//                 <div
//                     style={{
//                         width: "40px",
//                         height: "40px",
//                         backgroundColor: primaryColor,
//                         color: "white",
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         borderTopLeftRadius: "5px",
//                         borderBottomLeftRadius: "5px",
//                     }}
//                 >
//                     <Icon name={icon} inverted />
//                 </div>
//                 <div
//                     style={{
//                         flex: "1",
//                         paddingLeft: "10px",
//                         paddingRight: "10px",
//                     }}
//                 >
//                     {msg}
//                 </div>
//                 <div
//                     style={{
//                         color: "grey",
//                         paddingRight: "10px",
//                         cursor: "pointer",
//                         paddingLeft: "5px",
//                         paddingRight: "15px",
//                     }}
//                     onClick={onClose}
//                 >
//                     X
//                 </div>
//             </div>
//         ),
//         { position: "bottom-right" }
//     );
// }

export const notiSuccess = (msg) => {
    // noti(msg, "checkmark", "#37d681");
    toast.success(msg);
};

export const notiError = (msg) => {
    // noti(msg, "close", "red");
    toast.error(msg);
};

export const notiWarn = (msg, icon = "bell") => {
    // noti(msg, icon, "#f2cd15");
    toast(msg);
};
