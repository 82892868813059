import React from "react";
import { Header, Segment } from "semantic-ui-react";
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import log from "cslog";

const ViewsChart = ({ data, loading }) => {
    log.d(data, "DAta in Charts");
    return (
        <Segment
            loading={loading}
            style={{
                boxShadow: "0px 0px 18px -9px rgba(105, 105, 105, 1)",
            }}
        >
            <h3
                style={{
                    textAlign: "center",
                    color: "grey",
                    paddingTop: "10px",
                }}
            >
                Total Views
            </h3>
            {data && (
                <ResponsiveContainer width="95%" height={250}>
                    <LineChart data={data}>
                        {/* <LineChart width={800} height={250} data={data}> */}
                        <Line
                            type="monotone"
                            dataKey="total_views"
                            stroke="royalblue"
                            strokeWidth={3}
                        />
                       {/* <Line
                            type="monotone"
                            dataKey="unique_views"
                            stroke="darkblue"
                            strokeWidth={3}
                        /> */}
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis dataKey="name" />
                        <YAxis allowDecimals={false} />
                        <Tooltip />
                    </LineChart>
                </ResponsiveContainer>
            )}
        </Segment>
    );
};

export default ViewsChart;
