import axios from "axios";
import log from "cslog";
import { BASE_URL } from "../actions/apis";

export default function setAuthToken(token) {
    axios.defaults.baseURL = BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    if (token) {
        // axios.defaults.headers.common["Authorization"] = `QUREAL ${token}`;
        axios.defaults.headers.common["Authorization"] = token;
        log.p("Auth Token Header Set");
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
}
