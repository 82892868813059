import styles from "./styles/MagazineDetail.module.css";
import React, { useEffect, useState } from "react";
import { getBookDetail, removeEditor } from "../actions/bookActions";
import { useLocation, useParams } from "react-router-dom";
import log from "cslog";
import { Button, Icon, Image, List } from "semantic-ui-react";
import AddEditor from "./AddEditor";
import { notiError, notiSuccess } from "../utils/noti";
import { useRecoilValue } from "recoil";
import { authState } from "../db/authDb";

const MagazineDetail = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const auth = useRecoilValue(authState);
    const [isAuthor, setIsAuthor] = useState(false);

    useEffect(() => {
        log.d(id, "Loading");
        getBookDetail(
            id,
            (data) => {
                log.d(data, "BookDetailData");
                setData(data.data);
                setIsAuthor(auth.data.user.id === data.data.author);
            },
            () => {},
            () => {}
        );
    }, []);

    const removeOneEditor = (uid) => {
        removeEditor(
            id,
            { user_id: uid },
            (res) => {
                log.d(res, "Success Response");
                if (res.success) {
                    notiSuccess(res.message);
                    const newData = data.editors.filter(
                        (item) => item.id !== uid
                    );
                    log.d(newData, "NewData");
                    setData({
                        ...data,
                        editors: newData,
                    });
                } else {
                    notiError(res.message);
                }
            },
            () => {},
            () => {}
        );
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.top_wrapper}>
                {data ? (
                    <div
                        style={{
                            width: "100%",
                        }}
                    >
                        <h2>{data.name}</h2>
                        <h3>Editors</h3>
                        {/* <Button primary icon labelPosition="left">
                            <Icon name="user plus" />
                            Add Editor
                        </Button> */}
                        {isAuthor && (
                            <AddEditor
                                bid={id}
                                onNewEditor={(newEditor) => {
                                    setData({
                                        ...data,
                                        editors: [...data.editors, newEditor],
                                    });
                                }}
                            />
                        )}
                        <List selection verticalAlign="middle">
                            {data.editors.map((item) => (
                                <List.Item key={item.id} fluid>
                                    <Image
                                        avatar
                                        src="https://react.semantic-ui.com/images/avatar/small/helen.jpg"
                                    />
                                    <List.Content>
                                        <List.Header>
                                            {item.first_name} {item.last_name}
                                        </List.Header>
                                    </List.Content>
                                    {isAuthor && (
                                        <List.Content floated="right">
                                            {auth.data?.user?.id !== item.id ? (
                                                <Button
                                                    icon
                                                    onClick={() =>
                                                        removeOneEditor(item.id)
                                                    }
                                                >
                                                    <Icon name="user cancel" />
                                                </Button>
                                            ) : (
                                                <p style={{ padding: "10px" }}>
                                                    You
                                                </p>
                                            )}
                                        </List.Content>
                                    )}
                                </List.Item>
                            ))}
                        </List>
                    </div>
                ) : (
                    <h2>Loading...</h2>
                )}
            </div>
        </div>
    );
};

export default MagazineDetail;
