import axios from "axios";
import log from "cslog";
import { notiSuccess, notiError } from "../utils/noti";

import { fetcher } from "./apis";
import setAuthToken from "../utils/setAuthToken";
import useSWR from "swr";

// const fetcher = (url) => axios.get(url).then((res) => res.data);

export function useBookList() {
    const { data, error } = useSWR(`/books/books`, fetcher, {
        revalidateOnFocus: false,
    });

    return {
        bookList: data?.data,
        isLoading: !error && !data,
        isError: error,
    };
}

export const getBookList = (onSuccess, onError, onDone, authFailed) => {
    axios
        .get("/books/books")
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 401) {
                    authFailed();
                    return;
                }
                const data = err.response.data;
                if (data.constructor === "test".constructor) {
                    notiError("Something went wrong");
                } else {
                    notiError(data.message);
                }
                onError();
            } else if (err.request) {
                console.log("Request is made but response not received.");
                notiError("Request is Made but No response received");
            } else {
                console.log("Something went wrong");
                notiError("Something went wrong");
            }
        })
        .finally(() => onDone());
};

export const createBook = (data, onSuccess, onError, onDone) => {
    axios
        .post("/books/books", data, null)
        .then((res) => {
            notiSuccess("Book Created");
            onSuccess(res.data);
        })
        .catch((err) => {
            if (err.response) {
                const data = err.response.data;
                if (data.constructor === "test".constructor) {
                    notiError("Something went wrong");
                } else {
                    notiError(data.message);
                }
                onError();
            } else if (err.request) {
                console.log("Request is made but response not received.");
                notiError("Request is Made but No response received");
            } else {
                console.log("Something went wrong");
                notiError("Something went wrong");
            }
        })
        .finally(() => onDone());
};

export const getBookDetail = (bid, onSuccess, onError, onDone) => {
    axios
        .get(`/books/books/${bid}/dashboard`)
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((err) => {
            if (err.response) {
                const data = err.response.data;
                if (data.constructor === "test".constructor) {
                    notiError("Something went wrong");
                } else {
                    notiError(data.message);
                }
                onError();
            } else if (err.request) {
                console.log("Request is made but response not received.");
                notiError("Request is Made but No response received");
            } else {
                console.log("Something went wrong");
                notiError("Something went wrong");
            }
        })
        .finally(() => onDone());
};

export const checkEditor = (bid, data, onSuccess, onError, onDone) => {
    axios
        .post(`/books/books/${bid}/editors/check`, data, null)
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((err) => {
            if (err.response) {
                const data = err.response.data;
                if (data.constructor === "test".constructor) {
                    notiError("Something went wrong");
                } else {
                    notiError(data.message);
                }
                onError();
            } else if (err.request) {
                console.log("Request is made but response not received.");
                notiError("Request is Made but No response received");
            } else {
                console.log("Something went wrong");
                notiError("Something went wrong");
            }
        })
        .finally(() => onDone());
};

export const addEditor = (bid, data, onSuccess, onError, onDone) => {
    axios
        .post(`/books/books/${bid}/editors/add`, data, null)
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((err) => {
            if (err.response) {
                const data = err.response.data;
                if (data.constructor === "test".constructor) {
                    notiError("Something went wrong");
                } else {
                    notiError(data.message);
                }
                onError();
            } else if (err.request) {
                console.log("Request is made but response not received.");
                notiError("Request is Made but No response received");
            } else {
                console.log("Something went wrong");
                notiError("Something went wrong");
            }
        })
        .finally(() => onDone());
};

export const removeEditor = (bid, data, onSuccess, onError, onDone) => {
    axios
        .post(`/books/books/${bid}/editors/remove`, data, null)
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((err) => {
            log.d(err, "Error");
            if (err.response) {
                const data = err.response.data;
                if (data.constructor === "test".constructor) {
                    notiError("Something went wrong");
                } else {
                    notiError(data.message);
                }
                onError();
            } else if (err.request) {
                console.log("Request is made but response not received.");
                notiError("Request is Made but No response received");
            } else {
                console.log("Something went wrong");
                notiError("Something went wrong");
            }
        })
        .finally(() => onDone());
};
