import React, { useState } from "react";
import { Modal, Button, Icon, Form } from "semantic-ui-react";
import log from "cslog";
import { createBook } from "../actions/bookActions";
import { useRecoilState } from "recoil";
import { bookListState } from "../db/bookDb";

const CreateMagazine = ({ trigger }) => {
    const [open, setOpen] = useState(false);
    const [bookName, setBookName] = useState("");
    const [loading, setLoading] = useState(false);
    const [books, setBooks] = useRecoilState(bookListState);

    const createNewBook = () => {
        if (bookName === "") {
            return;
        }
        log.d(bookName, "Creating Book");
        setLoading(true);
        createBook(
            { name: bookName },
            (data) => {
                setBooks([data.data, ...books]);
            },
            () => {},
            () => {
                setLoading(false);
                setOpen(false);
            }
        );
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={trigger}
            size="mini"
        >
            <Modal.Header>Create New Book</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Book Title</label>
                        <input
                            placeholder="Enter here"
                            value={bookName}
                            onChange={(eve) => setBookName(eve.target.value)}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    content="Create"
                    labelPosition="right"
                    icon="checkmark"
                    positive
                    onClick={createNewBook}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default CreateMagazine;
