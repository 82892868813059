import React, { useState } from "react";
import { Modal, Button, Icon, Form, Message } from "semantic-ui-react";
import log from "cslog";
import { addEditor } from "../actions/bookActions";

const AddEditor = ({ bid, onNewEditor }) => {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(null);
    const [msg, setMsg] = useState(null);

    const addNewEditor = () => {
        if (email === "") {
            return;
        }
        log.d(email, "Adding Editor");
        setLoading(true);
        addEditor(
            bid,
            { email: email },
            (data) => {
                log.d(data, "Success");
                if (data.success) {
                    setMsg(data.message);
                    setErr(null);
                    setOpen(false);
                    onNewEditor(data.data);
                } else {
                    setErr(data.message);
                    setMsg(null);
                }
            },
            (data) => {
                log.d(data, "Error");
            },
            () => {
                setLoading(false);
                // setOpen(false);
            }
        );
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={
                <Button primary icon labelPosition="left">
                    <Icon name="add" /> Add Editor
                </Button>
            }
            size="mini"
        >
            <Modal.Header>Add New Editor</Modal.Header>
            <Modal.Content>
                <Form error={err} success={msg}>
                    <Form.Field>
                        <label>Email</label>
                        <input
                            placeholder="Enter here"
                            value={email}
                            onChange={(eve) => setEmail(eve.target.value)}
                        />
                        <Message error content={err} />
                        <Message success content={msg} />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    content="Add"
                    labelPosition="right"
                    icon="arrow right"
                    positive
                    onClick={addNewEditor}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default AddEditor;
