import React from "react";
import { Route, Redirect } from "react-router-dom";
import log from "cslog";
import { useQuery } from "../utils/hooks";
import { useRecoilValue } from "recoil";
import { authState } from "../db/authDb";

function PrivateRoute({ component: Component, ...rest }) {
    const query = useQuery();
    const auth = useRecoilValue(authState);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!auth.isAuthenticated) {
                    // return <Redirect to={`/account/login`} />;
                    return (
                        <Redirect
                            to={{
                                pathname: "/account/login",
                                query: { aciton: "newactionnnn" },
                                // ...(action ? { action: action } : {}),
                            }}
                        />
                    );
                }
                return <Component {...props} />;
            }}
        />
    );
}

export default PrivateRoute;
