import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";

const BookPicker = ({ data, selected, onBookChange }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (data) {
            const ops = [];
            data.forEach((item) => {
                ops.push({
                    key: item.id,
                    text: item.name,
                    value: item.id,
                });
            });
            setOptions(ops);
            if (!selected) {
                if (ops.length > 0) {
                    onBookChange(ops[0].value);
                }
            }
        }
    }, [data]);
    const ddata = [
        {
            key: 1,
            text: "AAA",
            value: 1,
        },
        {
            key: 2,
            text: "BBB",
            value: 2,
        },
        {
            key: 3,
            text: "CCC",
            value: 3,
        },
    ];
    return (
        <Dropdown
            placeholder="Select Friend"
            selection
            value={selected}
            options={options}
            loading={!data}
            onChange={(e, { value }) => onBookChange(value)}
        />
    );
};

export default BookPicker;
