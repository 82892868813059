import styles from "./styles/MagazinesView.module.css";
import React, { useEffect, useState } from "react";
import { Button, Grid, Icon, Segment, Placeholder } from "semantic-ui-react";
import MagazineCard from "./MagazineCard";
import CreateMagazine from "./CreateMagazine";
import { getBookList, useBookList } from "../actions/bookActions";
import { useHistory } from "react-router-dom";
import { Fragment } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { anSelectedBook_id, bookListState } from "../db/bookDb";
import { authState } from "../db/authDb";
import NoBookPlaceholder from "./NoBookPlaceholder";
import log from "cslog";

const LoadingGrid = () => {
    return [1, 2, 3, 4, 5, 6].map((item) => (
        <Segment raised key={item}>
            <Placeholder fluid>
                <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
        </Segment>
    ));
};

const MagazinesView = () => {
    // const [books, setBooks] = useState([]);
    // const { bookList, isLoading, error } = useBookList();
    const [books, setBooks] = useRecoilState(bookListState);
    const history = useHistory();
    const [auth, setAuth] = useRecoilState(authState);
    const setSelectedBook_id = useSetRecoilState(anSelectedBook_id);

    // log.d(bookList, "BookList in MV");

    // useEffect(() => {
    //     setBooks(bookList);
    // }, [bookList]);

    useEffect(() => {
        if (!books) {
            getBookList(
                (data) => setBooks(data.data),
                () => {},
                () => {},
                () =>
                    setAuth({
                        isAuthenticated: false,
                        data: null,
                    })
            );
        }
    }, []);

    // log.d(books, "Books");

    return (
        <div className={styles.wrapper}>
            <div className={styles.top_wrapper}>
                <h2>Magazines </h2>
                {/* <Button positive icon labelPosition="left">
                    <Icon name="add" /> Create New Book
                </Button> */}
                <CreateMagazine
                    trigger={
                        <Button positive icon labelPosition="left">
                            <Icon name="add" /> Create New Book
                        </Button>
                    }
                />
            </div>

            {books?.length === 0 && <NoBookPlaceholder />}
            <div className={styles.item_wrapper}>
                {books === null ? (
                    <LoadingGrid />
                ) : (
                    books.map((item) => {
                        return (
                            <MagazineCard
                                key={item.id}
                                data={item}
                                onClick={() =>
                                    history.push(`/magazines/${item.id}`)
                                }
                                onAnClick={() => {
                                    setSelectedBook_id(item.id);
                                    history.push(`/analytics`);
                                }}
                                token={auth.data?.token}
                                user={auth.data.user}
                            />
                        );
                    })
                )}
            </div>
        </div>
    );
};

export default MagazinesView;
