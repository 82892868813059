import { atom } from "recoil";

export const authState = atom({
    key: "authState",
    default: {
        // isAuthenticated: false,
        isAuthenticated: localStorage.getItem("jwtToken") ? true : false,
        data: {
            token: localStorage.getItem("jwtToken"),
            user: localStorage.getItem("user")
                ? JSON.parse(localStorage.getItem("user"))
                : null,
        },
    },
});
