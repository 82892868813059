import styles from "./styles/Home.module.css";
import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { logout } from "./actions/authActions";
import { authState } from "./db/authDb";
import Navbar from "./common/Navbar";
import { Route, Switch } from "react-router-dom";

import AnalyticsView from "./analytics/AnalyticsView";
import MagazinesView from "./magazines/MagazinesView";
import MagazineDetail from "./magazines/MagazineDetail";
import log from "cslog";

const Home = () => {
    log.h2("Welcom Home");
    return (
        <div className={styles.wrapper}>
            <div className={styles.top}>
                <Navbar />
            </div>
            <div className={styles.display}>
                <Switch>
                    <Route path="/analytics" component={AnalyticsView} />
                    <Route path="/magazines" exact component={MagazinesView} />
                    <Route path="/magazines/:id" component={MagazineDetail} />
                </Switch>
            </div>
        </div>
    );
};

export default Home;
