import styles from "./styles/MagazineCard.module.css";
import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { EDITOR_URL, VIEWER_URL } from "../actions/apis";
import Moment from "react-moment";

const MagazineCard = ({ data, onClick, token, user, onAnClick }) => {
    return (
        <div key={data.id} className={styles.wrapper}>
            <div className={styles.img_area}></div>
            <div className={styles.info_area}>
                <div className={styles.text_area}>
                    <h4>{data.name}</h4>
                    <p>
                        Last Edited: <Moment fromNow>{data.updated_at}</Moment>
                    </p>
                    {user.id === data.author && <span>Author</span>}
                </div>

                <div className={styles.action_area}>
                    <Button
                        primary
                        as="a"
                        target="_blank"
                        href={`${EDITOR_URL}?book=${data.id}&t=${token}`}
                    >
                        Edit
                    </Button>
                    <Button
                        icon
                        as="a"
                        target="_blank"
                        href={`${VIEWER_URL}/book/${data.id}`}
                    >
                        <Icon name="eye" />
                    </Button>
                    <Button icon onClick={onAnClick}>
                        <Icon name="line graph" />
                    </Button>
                    <Button icon onClick={onClick}>
                        <Icon name="setting" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default MagazineCard;
