import React from "react";
import ReactDOM from "react-dom";
import setAuthToken from "./utils/setAuthToken";

import App from "./App";

if (localStorage.getItem("jwtToken")) {
    setAuthToken(localStorage.getItem("jwtToken"));
    // store.dispatch(setCurrentUser(JSON.parse(localStorage.getItem("user"))));
} else {
    setAuthToken();
}

ReactDOM.render(<App />, document.querySelector("#root"));
