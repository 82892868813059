import React from "react";
import { Fragment } from "react";
import { Loader } from "semantic-ui-react";
import styles from "./styles/StatisticsView.module.css";

const Card = ({ title, value, color = "black", loading = false }) => {
    return (
        <div className={styles.card}>
            {loading ? (
                <Loader active inline="centered" />
            ) : (
                <Fragment>
                    <h3 className={styles.card_title}>{title}</h3>
                    <p
                        className={styles.card_value}
                        style={{
                            color: color,
                        }}
                    >
                        {value}
                    </p>
                </Fragment>
            )}
        </div>
    );
};

const StatisticsView = ({ data, loading }) => {
    if (!data) {
        return <></>;
    }
    return (
        <div className={styles.wrapper}>
            <Card
                title="Total Views"
                value={data.total_views}
                color="royalblue"
                loading={loading}
            />
            <Card
                title="Unique Views"
                value={data.unique_views}
                color="darkblue"
                loading={loading}
            />
            <Card
                title="Average Views Per Day"
                value={data.average_views}
                color="green"
                loading={loading}
            />
            <Card
                title="Coming Soon"
                value={0}
                color="orange"
                loading={loading}
            />
        </div>
    );
};

export default StatisticsView;
