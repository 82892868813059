import React from "react";
import { Segment, Header, Icon, Button } from "semantic-ui-react";
import CreateMagazine from "./CreateMagazine";

const NoBookPlaceholder = () => {
    return (
        <Segment placeholder>
            <Header icon>
                <Icon name="edit outline" />
                No Magazine Created, Create Your First Magazine
            </Header>
            <CreateMagazine trigger={<Button primary>Create Book</Button>} />
        </Segment>
    );
};

export default NoBookPlaceholder;
