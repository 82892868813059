import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Icon, Menu, Label, Dropdown } from "semantic-ui-react";
// import history from "../db/history";
import { logout } from "../actions/authActions";
import { authState } from "../db/authDb";
import log from "cslog";
import { bookListState } from "../db/bookDb";
import ChangePasswordDialog from "../auth/ChangePasswordDialog";

const Navbar = () => {
    const history = useHistory();
    const [auth, setAuth] = useRecoilState(authState);
    const setBooks = useSetRecoilState(bookListState);
    // const setAuth = useSetRecoilState(authState);
    const location = useLocation();

    log.d(auth, "Auth");

    const logoutUser = () => {
        logout(() => {
            setAuth({
                isAuthenticated: false,
                data: null,
            });
            setBooks(null);
        });
    };

    useEffect(() => {
        if (location.pathname === "/") {
            history.push("/magazines");
        }
    }, []);

    return (
        <Menu pointing secondary size="large">
            <Menu.Item fitted>
                <img
                    src={process.env.PUBLIC_URL + "qureal_logo_colored.png"}
                    style={{
                        height: "35px",
                        width: "auto",
                        marginLeft: 5,
                        marginRight: 0,
                        marginTop: "-10px",
                        marginBottom: "-10px",
                    }}
                    alt="Logo"
                />

                <Label
                    color="red"
                    style={{
                        marginRight: "25px",
                    }}
                >
                    Beta
                </Label>
            </Menu.Item>
            <Menu.Menu position="right">
                <Menu.Item
                    name="Analytics"
                    active={location.pathname === "/analytics"}
                    onClick={() => {
                        // setActiveMenu("analytics");
                        history.push("/analytics");
                    }}
                >
                    <Icon
                        name="line graph"
                        color={
                            location.pathname === "/analytics" ? "blue" : "grey"
                        }
                    />{" "}
                    Analytics
                </Menu.Item>
                <Menu.Item
                    name="Analytics"
                    active={location.pathname === "/magazines"}
                    onClick={() => {
                        // setActiveMenu("magazines");
                        history.push("/magazines");
                    }}
                >
                    <Icon
                        name="book"
                        color={
                            location.pathname === "/magazines" ? "blue" : "grey"
                        }
                    />{" "}
                    Magazines
                </Menu.Item>
            </Menu.Menu>
            <Menu.Menu position="right">
                <Menu.Item>
                    <Icon name="bell outline" />
                </Menu.Item>
                <Menu.Item name={`Welcome ${auth.data?.user?.first_name}`} />

                <Dropdown item icon="user">
                    <Dropdown.Menu direction="left">
                        <ChangePasswordDialog
                            trigger={
                                <Dropdown.Item
                                    icon="key"
                                    text="Change Password"
                                />
                            }
                            logout={logoutUser}
                        />
                        <Dropdown.Item
                            icon="shutdown"
                            text="Logout"
                            onClick={logoutUser}
                            color="red"
                        />
                    </Dropdown.Menu>
                </Dropdown>
                <div
                    style={{
                        width: "10px",
                    }}
                />
            </Menu.Menu>
        </Menu>
    );
};

export default Navbar;
