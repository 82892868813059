import styles from "./styles/AnalyticsView.module.css";
import React, { useEffect, useState } from "react";
import { Dropdown, Loader, Menu, Segment } from "semantic-ui-react";
import ViewsChart from "./ViewsChart";
import useSWR from "swr";
import { useAnalytics } from "../actions/analyticsActions";
import log from "cslog";
import StatisticsView from "./StatisticsView";
import BookPicker from "./BookPicker";
import { useBookList } from "../actions/bookActions";
import { useRecoilState } from "recoil";
import { anSelectedBook_id } from "../db/bookDb";
import DeviceChart from "./DeviceChart";
import axios from "axios";

function join(t, a, s) {
    function format(m) {
        let f = new Intl.DateTimeFormat("en", m);
        return f.format(t);
    }
    return a.map(format).join(s);
}

function getFormattedDt(dt) {
    let a = [{ day: "numeric" }, { month: "short" }, { year: "numeric" }];

    let s = join(dt, a, "-");
    return s;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function isSameDay(dt1, dt2) {
    return (
        dt1.getFullYear() === dt2.getFullYear() &&
        dt1.getMonth() === dt2.getMonth() &&
        dt1.getDate() === dt2.getDate()
    );
}

const AnalyticsView = () => {
    const { bookList } = useBookList();
    // const [book_id, setBook_id] = useState(null);
    const [book_id, setBook_id] = useRecoilState(anSelectedBook_id);
    const [duration, setDuration] = useState(7);
    const { data, isLoading, isError } = useAnalytics(book_id, duration);
    const [rows, setRows] = useState(null);
    const [stats, setStats] = useState(null);
    const [devices, setDevices] = useState(null);
    // const [data, setData] = useState(null);
    // const [isLoading, setLoading] = useState(false);

    // log.d(data, "NOW DATA");
    // log.d(book_id, "BookID");

    // useEffect(() => {
    //     if (!book_id) {
    //         return;
    //     }
    //     setLoading(true);
    //     axios
    //         .get(`/an/get_an_all/${book_id ? book_id : 1}?days=${duration}`)
    //         .then((res) => {
    //             const data = res.data;
    //             if (data.success) {
    //                 setData(data);
    //             }
    //         })
    //         .catch((err) => {})
    //         .then(() => {
    //             setLoading(false);
    //         });
    // }, [book_id]);

    useEffect(() => {
        if (data) {
            log.d(data, "Raw Data");
            let start_dt = new Date(data.data.start_dt);
            const end_dt = new Date(data.data.end_dt);
            const rrows = [];
            let totalViews = 0;
            let uniqueViews = 0;
            let count = 0;
            const drows = data?.data?.views;

            //If no data row available
            if (drows.length === 0) {
                while (
                    (start_dt.getTime() !== end_dt.getTime()) &
                    (count < 10)
                ) {
                    count += 1;
                    rrows.push({
                        name: getFormattedDt(start_dt),
                        total_views: 0,
                        unique_views: 0,
                    });
                    start_dt.setDate(start_dt.getDate() + 1);
                }
            }

            //Fill zero in missing rows
            drows.sort(function (a, b) {
                var c = new Date(a.dt);
                var d = new Date(b.dt);
                return c - d;
            });
            let last_el = drows[drows.length - 1];
            log.d(last_el, "Last El");
            if (last_el) {
                last_el = new Date(last_el.dt);
                if (!isSameDay(end_dt, last_el)) {
                    drows.push({
                        dt: end_dt,
                        total_views: 0,
                        unique_views: 0,
                    });
                }
            }
            log.d(drows, "Before Applying logic");
            drows.forEach((item) => {
                const dt = new Date(item.dt);
                log.d(dt, "Next DT");
                count = 0;
                if (isSameDay(start_dt, dt)) {
                    // log.d(start_dt, "NEXT 1");
                    // rrows.push({
                    //     name: getFormattedDt(start_dt),
                    //     total_views: 0,
                    //     unique_views: 0,
                    // });
                } else {
                    while (count < 90 && !isSameDay(start_dt, dt)) {
                        log.d(start_dt, "NEXT 0");
                        rrows.push({
                            name: getFormattedDt(start_dt),
                            total_views: 0,
                            unique_views: 0,
                        });
                        start_dt.setDate(start_dt.getDate() + 1);
                        // log.d(start_dt, "Modi Start_dt");
                        count += 1;
                    }

                    // log.p("Loop broken");
                }
                if (isSameDay(start_dt, dt)) {
                    log.d(start_dt, "NEXT 2");
                    totalViews += item.total_views;
                    uniqueViews += item.unique_views;
                    rrows.push({
                        name: getFormattedDt(start_dt),
                        total_views: item.total_views,
                        unique_views: item.unique_views,
                    });
                    start_dt.setDate(start_dt.getDate() + 1);
                }
                // while (!isSameDay(start_dt, dt)) {
                //     log.d(start_dt, "Final Next");
                //     start_dt.setDate(start_dt.getDate() + 1);
                // }
                // while (start_dt.getTime() !== dt.getTime()) {
                //     log.d(start_dt, "Final DT");
                //     start_dt.setDate(start_dt.getDate() + 1);
                // }
            });
            // drows.forEach((item) => {
            //     const dt = new Date(item.dt);
            //     while (
            //         (start_dt.getTime() !== end_dt.getTime()) &
            //         (count < 10)
            //     ) {
            //         count += 1;
            //         rrows.push({
            //             name: getFormattedDt(start_dt),
            //             total_views: 0,
            //             unique_views: 0,
            //         });
            //         start_dt.setDate(start_dt.getDate() + 1);
            //     }
            //     // log.d("Loop escaped");
            //     // if (start_dt === dt) {
            //     totalViews += item.total_views;
            //     uniqueViews += item.unique_views;
            //     rrows.push({
            //         name: getFormattedDt(dt),
            //         total_views: item.total_views,
            //         unique_views: item.unique_views,
            //     });
            //     start_dt.setDate(start_dt.getDate() + 1);
            //     // }
            // });
            setRows(rrows);
            setStats({
                total_views: totalViews,
                unique_views: uniqueViews,
                average_views: Math.round(totalViews / duration),
            });

            //Devices
            const ddevices = data?.data?.device;
            const dd_out = [];
            let dtotal = 0;
            ddevices.forEach((item) => {
                dtotal += item.count;
            });
            ddevices.forEach((item) => {
                dd_out.push({
                    name: capitalizeFirstLetter(item.device),
                    value: Math.round((item.count * 100) / dtotal),
                });
            });
            setDevices(dd_out);
        }
    }, [data, duration]);

    log.d(rows, "NOW Rows");

    // if (!book_id) {
    //     return (
    //         <p
    //             style={{
    //                 width: "100%",
    //                 height: "80vh",
    //                 display: "flex",
    //                 justifyContent: "center",
    //                 alignItems: "center",
    //             }}
    //         >
    //             No Book Available
    //         </p>
    //     );
    // }

    return (
        <div className={styles.wrapper}>
            <div className={styles.head}>
                <BookPicker
                    data={bookList}
                    selected={book_id}
                    onBookChange={(value) => setBook_id(value)}
                />
                <Menu compact>
                    <Menu.Item
                        name="editorials"
                        active={duration === 7}
                        onClick={() => setDuration(7)}
                    >
                        Last 7 Days
                    </Menu.Item>
                    <Menu.Item
                        name="editorials"
                        active={duration === 30}
                        onClick={() => setDuration(30)}
                    >
                        Last 30 Days
                    </Menu.Item>
                    <Menu.Item
                        name="editorials"
                        active={duration === 90}
                        onClick={() => setDuration(90)}
                    >
                        3 Months
                    </Menu.Item>
                </Menu>
            </div>
            <StatisticsView data={stats} loading={isLoading} />
            <ViewsChart data={rows} loading={isLoading} />
            <DeviceChart data={devices} loading={isLoading} />
        </div>
    );
};

export default AnalyticsView;
