import styles from "./styles/Login.module.scss";
import React from "react";
import { Switch, Route } from "react-router-dom";

import Loadable from "react-loadable";
import Loading from "../Loading";

// import Login from "./Login";
// import SignUp from "./SignUp";
// import PasswordResetMail from "./PasswordResetMail";
const Login = Loadable({
    loader: () => import("./Login"),
    loading: Loading,
});

const Register = Loadable({
    loader: () => import("./Register"),
    loading: Loading,
});
// const SignUp = Loadable({
//     loader: () => import("./SignUp"),
//     loading: Loading,
// });
// const PasswordResetMail = Loadable({
//     loader: () => import("./PasswordResetMail"),
//     loading: Loading,
// });

const AuthView = () => {
    return (
        <div className={styles.app}>
            <div className={styles.side}>
                <img
                    src={process.env.PUBLIC_URL + "/Qureal_logo.png"}
                    alt="Qureal Logo"
                    className={styles.logo}
                />
                <p className={styles.sideText}>
                    Create and publish stunning e-Magazines within minutes with
                    easy to use drag-n-drop editor.
                </p>
            </div>
            <div className={styles.wrapper}>
                <Switch>
                    <Route path="/account/login" component={Login} />
                    <Route path="/account/signup" component={Register} />
                    {/* <Route
                        path="/account/reset_password"
                        component={PasswordResetMail}
                    /> */}
                </Switch>
            </div>
        </div>
    );
};

export default AuthView;
