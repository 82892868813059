import axios from "axios";
import { notiSuccess, notiError } from "../utils/noti";
import setAuthToken from "../utils/setAuthToken";
import { logout } from "./authActions";

const ENV = "prod"; // "prod", "test", "local"

//====================================================

// let url = "https://qbackend.qureal.com";
let url = "http://127.0.0.1:5000";
let editorUrl = "http://127.0.0.1:3000";
let viewerUrl = "http://127.0.0.1:3000";

if (ENV === "prod") {
    url = "https://magazineapi.qureal.com";
    editorUrl = "https://magazineeditor.qureal.com";
    viewerUrl = "https://ereader.qureal.com";
} else if (ENV === "local") {
    url = "http://127.0.0.1:5000";
}

export const BASE_URL = url;
export const EDITOR_URL = editorUrl;
export const VIEWER_URL = viewerUrl;

export const GOOGLE_CLIENT_ID =
    "23968924661-p300of2utpbbbifdpstkp06eo3ahbek3.apps.googleusercontent.com";

// export const API = axios.create({
// 	baseURL: url,
// });

const API = axios.create({
    baseURL: url,
});

const errHandler = (err) => {
    console.log("Error in Response");
    // console.log(err.toJSON());
    console.log(err);
    if (err.response) {
        if (err.response.status === 401) {
            notiError("UNAUTHORIZED");
            setAuthToken();
            // store.dispatch(logout());
            return;
        }
        const data = err.response.data;
        if (data.constructor === "test".constructor) {
            notiError("Something went wrong");
        } else {
            for (let field in data) {
                if (field === "non_field_errors") notiError(data[field].join());
                else {
                    if (Array.isArray(data[field]))
                        notiError(field + ": " + data[field].join());
                    else notiError(field + ": " + data[field]);
                }
            }
        }
    } else if (err.request) {
        console.log("Request is made but response not received.");
        notiError("Request is Made but No response received");
    } else {
        console.log("Something went wrong");
        notiError("Something went wrong");
    }
};

export { API, errHandler, ENV };

export const fetcher = (url) => axios.get(url).then((res) => res.data);
